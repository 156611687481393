import { print } from 'graphql/language/printer';
import { DocumentNode, parse, visit } from 'graphql';
import gql from 'graphql-tag';
import { importFragment } from '@/graphql/_Fragments/FragmentMapping';
import GqlMutationDefinition from '@/graphql/_Tools/GqlMutationDefinition';
import GqlQueryDefinition from '@/graphql/_Tools/GqlQueryDefinition';
import { BasicTypes } from '@/utils/types/BasicTypes';
import GqlSubscriptionDefinition from '@/graphql/_Tools/GqlSubscriptionDefinition';

const gqlBaseTemplate = `%alias% %operation%(%params%) {
    ...%fragmentName%
  }
`;

const gqlTemplate = `%operationType% %operationName%(%variableDefinitions%) {
  ${gqlBaseTemplate}
}
%fragments%
`;

const gqlComposeTemplate = `%operationType% %operationName% {
  %queries%
}
%fragments%
`;

enum OperationType {
  QUERY = 'query',
  MUTATION = 'mutation',
  SUBSCRIPTION = 'subscription'
}

const removeDuplicateFragments = (query: string): string => {
  const fragmentDefs: Record<string, object> = {};
  const visitedQuery = visit(parse(query), {
    FragmentDefinition: {
      enter(node) {
        if (fragmentDefs[node.name.value]) {
          return null;
        }
        fragmentDefs[node.name.value] = node;
        return undefined;
      },
    },
  });
  return print(visitedQuery);
};

const replaceMagicArgs = (query: string, args: Record<string, BasicTypes> | undefined): string => {
  if (args && Object.keys(args).length > 0) {
    let result = query;
    Object.keys(args)
      .forEach((k) => {
        const regex = new RegExp(`"*%${k}%"*`, 'gm');
        if (k.endsWith('_int') && !Number.isNaN(args[k]) && typeof args[k] === 'string') {
          result = result.replaceAll(regex, `${args && args[k] ? parseInt(args[k] as string, 10) : ''}`);
        } else if (k.endsWith('_bool')
          && typeof args[k] === 'string'
          && ['true', 'false'].includes((args[k] as string).toLowerCase())) {
          result = result.replaceAll(regex, `${args && args[k] ? (args[k] as string).toLowerCase() === 'true' : false}`);
        } else if (typeof args[k] === 'number') {
          result = result.replaceAll(regex, `${args && args[k] ? args[k] : ''}`);
        } else if (Array.isArray(args[k])) {
          const value = `[${(args[k] as []).map((val) => `"${val}"`)
            .join(', ')}]`;
          result = result.replaceAll(regex, `${args && args[k] && value ? value : ''}`);
        } else if (k === 'filter') {
          result = result.replaceAll(regex, `${args && args[k] ? args[k] : ''}`);
        } else {
          result = result.replaceAll(regex, `"${args && args[k] ? args[k] : ''}"`);
        }
      });
    return result;
  }
  return query;
};

const buildComposeGql = (
  config: {
    operationType: OperationType;
    operationName: string;
    definitions: Array<{
      operation: string;
      gqlDefinition: GqlQueryDefinition | GqlMutationDefinition;
      fragmentName: string;
      alias?: string;
    }>;
    authUser?: string;
    magicArgs?: Record<string, BasicTypes>;
  },
): Promise<DocumentNode> => {
  const fragmentPromises = config.definitions
    .map((def) => (def.fragmentName ? importFragment(def.fragmentName) : Promise.resolve('')));
  return Promise.all(fragmentPromises)
    .then((fragments) => {
      const queries = config.definitions.reduce((accumulation, def) => {
        let query = gqlBaseTemplate
          .replace('%params%',
            JSON.stringify(def.gqlDefinition.variables)
              .replace(/"([^"]+)":/g, '$1:')
              .trim()
              .slice(1, -1))
          .replace(/orderBy:\S*\["(.*)"\]/g, (match) => match.replaceAll('"', ''))
          .replace('%alias%', def.alias ? `${def.alias}:` : '')
          .replace('%operation%', def.operation)
          .replace('%fragmentName%', def.fragmentName)
          .replaceAll(/\(\)/g, '');
        if (def.fragmentName.length === 0) {
          query = query.replaceAll(/{\s+\.{3}\s+}/gm, '');
        }
        return `${accumulation} ${query}`;
      }, '');
      let result = gqlComposeTemplate.replace('%operationType%', config.operationType)
        .replace('%operationName%', config.operationName)
        .replace('%queries%', queries)
        .replace('%fragments%', fragments.reduce((accumulation, fragment) => `${accumulation} ${fragment}`, ''));
      const magicArgs = config.definitions
        .reduce((accumulation, def) => ({ ...accumulation, ...(def.gqlDefinition.magicArgs || {}) }), {});
      result = replaceMagicArgs(result, { ...magicArgs, ...config.magicArgs })
        .replaceAll(/"*%authUser%"*/g, `"${config.authUser ? config.authUser : ''}"`);
      return gql(removeDuplicateFragments(result));
    });
};

const buildGql = (
  config: {
    operationType: OperationType;
    operationName: string;
    operation: string;
    gqlDefinition: GqlQueryDefinition | GqlMutationDefinition | GqlSubscriptionDefinition;
    fragmentName: string;
    alias?: string;
    authUser?: string;
    magicArgs?: Record<string, BasicTypes>;
    params?: Record<string, BasicTypes>;
  },
): Promise<DocumentNode> => {
  let promise = Promise.resolve('');
  if (config.fragmentName) {
    promise = importFragment(config.fragmentName);
  }
  return promise.then((fragments) => {
    let result = gqlTemplate.replace('%operationType%', config.operationType)
      .replace('%operationName%', config.operationName)
      .replace('%variableDefinitions%', config.gqlDefinition.declaration)
      .replace('%params%', config.gqlDefinition.parameter)
      .replace('%alias%', config.alias ? `${config.alias}:` : '')
      .replace('%operation%', config.operation)
      .replace('%fragmentName%', config.fragmentName)
      .replace('%fragments%', fragments);
    if (config.fragmentName.length === 0) {
      result = result.replaceAll(/{\s+\.{3}\s+}/gm, '');
    }
    result = replaceMagicArgs(result, { ...(config.gqlDefinition.magicArgs || {}), ...config.magicArgs })
      .replaceAll(/"*%authUser%"*/g, `"${config.authUser ? config.authUser : ''}"`);
    return gql(removeDuplicateFragments(result));
  });
};

const buildMutationGql = (
  config: {
    operationName: string;
    operation: string;
    gqlDefinition: GqlMutationDefinition;
    fragmentName: string;
    alias?: string;
    authUser?: string;
    magicArgs?: Record<string, BasicTypes>;
  },
): Promise<DocumentNode> => buildGql({
  ...config,
  operationType: OperationType.MUTATION,
});

const buildQueryGql = (
  config: {
    operationName: string;
    operation: string;
    gqlDefinition: GqlQueryDefinition;
    fragmentName: string;
    alias?: string;
    authUser?: string;
    magicArgs?: Record<string, BasicTypes>;
    params?: Record<string, BasicTypes>;
  },
): Promise<DocumentNode> => buildGql({
  ...config,
  operationType: OperationType.QUERY,
});

const buildSubscriptionGql = (
  config: {
    operationName: string;
    operation: string;
    gqlDefinition: GqlSubscriptionDefinition;
    fragmentName: string;
    alias?: string;
    authUser?: string;
    magicArgs?: Record<string, BasicTypes>;
    params?: Record<string, BasicTypes>;
  },
): Promise<DocumentNode> => buildGql({
  ...config,
  operationType: OperationType.SUBSCRIPTION,
});

/**
 * This function should only be used when you try to add many queries
 * with the same type ('fts', 'do-count', 'do-distinct')
 * @param config
 */
const buildComposeQueryGql = (
  config: {
    operationName: string;
    definitions: Array<{
      operation: string;
      gqlDefinition: GqlQueryDefinition;
      fragmentName: string;
      alias?: string;
    }>;
    authUser?: string;
    magicArgs?: Record<string, BasicTypes>;
  },
): Promise<DocumentNode> => buildComposeGql({
  ...config,
  operationType: OperationType.QUERY,
});

const buildComposeMutationGql = (
  config: {
    operationName: string;
    definitions: Array<{
      operation: string;
      gqlDefinition: GqlMutationDefinition;
      fragmentName: string;
      alias?: string;
    }>;
    authUser?: string;
  },
): Promise<DocumentNode> => buildComposeGql({
  ...config,
  operationType: OperationType.MUTATION,
});

export {
  buildQueryGql,
  buildMutationGql,
  buildSubscriptionGql,
  buildComposeQueryGql,
  buildComposeMutationGql,
  replaceMagicArgs,
};
