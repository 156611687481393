import { render, staticRenderFns } from "./MessageBoxHeader.vue?vue&type=template&id=116aed25&scoped=true&"
import script from "./MessageBoxHeader.vue?vue&type=script&lang=ts&"
export * from "./MessageBoxHeader.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/chat/MessageBoxHeader.scss?vue&type=style&index=0&id=116aed25&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "116aed25",
  null
  
)

export default component.exports