import Community from '@/models/graphql/Community';
import CommunityUser from '@/models/graphql/CommunityUser';
import Exhibitor from '@/models/graphql/Exhibitor';
import Meeting from '@/models/graphql/Meeting';
import { ToolbarMenuActions } from '@/utils/enums/ToolbarMenuActions';
import { format, isBefore } from 'date-fns';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import MeetingParticipant from '@/models/graphql/MeetingParticipant';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import AgendaStoreHelper from '@/utils/helpers/AgendaStoreHelper';
import ViewMode from '@/utils/enums/agenda/ViewMode';
import MeetingParticipantState from '@/utils/enums/MeetingParticipantState';
import Event from '@/utils/types/Event';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';

const companyCalendarStore = namespace('CompanyCalendarStore');
const agendaStore = namespace('AgendaStore');

@Component
export default class MeetingRequestWrapper extends Vue {
  @Prop({ required: false })
  protected uid!: string;

  @Prop({ required: false })
  protected id!: number;

  @Prop({ required: false })
  protected community!: Community;

  @Prop({ required: false })
  protected schemaCode!: string;

  @Prop({ required: false })
  protected message!: string;

  @Prop({ required: false })
  protected timezoneName!: string;

  @Prop({ required: false })
  protected startTime!: string;

  @Prop({ required: false })
  protected endTime!: string;

  @Prop({ required: false })
  protected startTimestamp!: number;

  @Prop({ required: false })
  protected endTimestamp!: number;

  @Prop({ required: false })
  protected deleted!: boolean;

  @Prop({ required: false })
  protected trash!: boolean;

  @Prop({ required: false })
  protected readState!: boolean;

  @Prop({ required: false })
  protected exhibitor!: Exhibitor;

  @Prop({ required: false })
  protected user!: CommunityUser;

  @Prop({ required: false })
  protected companyRep!: CommunityUser;

  @Prop({ required: false })
  protected guests!: CommunityUser[];

  @Prop({ required: false })
  protected meeting!: Meeting;

  @Prop({ required: false })
  protected createdTime!: Date;

  @companyCalendarStore.Action
  protected markMeetingRequestAsRead!: (payload: {uid: string; companyUid: string}) => Promise<void>;

  @companyCalendarStore.Action
  protected deleteMeetingRequest!: (uid: string) => void;

  @companyCalendarStore.Action
  protected archiveMeetingRequest!: (payload: { uid: string; trash: boolean }) => void;

  @companyCalendarStore.Mutation
  protected removeMeetingRequest!: (uid: string) => void;

  @companyCalendarStore.Getter
  protected moderators!: Array<CommunityUser>;

  @State
  protected dateLocale!: Locale;

  @State
  protected authUser!: CommunityUser;

  @State
  protected selectedTzName!: string;

  protected slotSelected = '';

  protected slotIndexSelected = '';

  protected moderatorSelected: CommunityUser | null = null;

  @agendaStore.Action
  private createMeeting!: (payload: {
    meeting: Partial<Meeting>;
    userUid: string;
    meetingRequestUid: string;
  }) => Promise<Meeting>;

  @agendaStore.Getter
  private fetchMeetingSaveLoading!: boolean;

  @agendaStore.Mutation
  private setEventEdited!: (e: Partial<Event>) => void;

  @agendaStore.Mutation
  private setDayBeingViewed!: (date: string) => void;

  @agendaStore.Mutation
  private setViewMode!: (string: ViewMode) => void;

  get isOpen(): boolean {
    return !this.isPending && !this.isScheduled && !this.isCompleted;
  }

  get isPending(): boolean {
    return !this.isCompleted
      && !!this.meeting?.participants?.some((participant) => participant.state !== MeetingParticipantState.ACCEPTED);
  }

  get isScheduled(): boolean {
    return !this.isCompleted
      && !!this.meeting?.participants?.every((participant) => participant.state === MeetingParticipantState.ACCEPTED);
  }

  get isCompleted(): boolean {
    return !!this.meeting?.endTimestamp
      && isBefore(
        DateTimeHelper.utcToZonedTimeDate(this.meeting.endTimestamp * 1000, this.selectedTzName),
        DateTimeHelper.utcToZonedTimeDate(new Date(), this.selectedTzName),
      );
  }

  private get firstName(): string | null {
    if (this.user && this.user.firstName) {
      return this.user.firstName;
    }
    return null;
  }

  private get lastName(): string | null {
    if (this.user && this.user.lastName) {
      return this.user.lastName;
    }
    return null;
  }

  private get image(): string | undefined {
    if (this.user) {
      const hydrated = CommunityUser.hydrate(this.user);
      if (hydrated) {
        return hydrated.profilePicture;
      }
    }
    return undefined;
  }

  private get title(): string | null {
    if (this.user && this.user.lastName) {
      const hydrated = CommunityUser.hydrate(this.user);
      if (hydrated) {
        return hydrated.fullName;
      }
    }
    return null;
  }

  private get subtitle(): string | null {
    if (this.user) {
      let str = '';
      if (this.user && this.user.jobTitle) {
        str = this.user.jobTitle;
        if (this.user && this.user.employerName) {
          return `${str} ${this.$t('calendar.tabs.requests.at')} ${this.user.employerName}`;
        }
        return str;
      }
    }
    return null;
  }

  private get requestTime(): string {
    if (this.createdTime) {
      return `${this.$t('calendar.tabs.requests.created-time', {
        dayAndMonth: format(
          DateTimeHelper.utcToZonedTimeDate(
            new Date(this.createdTime),
            this.selectedTzName,
          ), 'MMM dd',
        ),
        hourAndMin: format(
          DateTimeHelper.utcToZonedTimeDate(
            new Date(this.createdTime),
            this.selectedTzName,
          ), 'hh:mm a',
        ),
      })}`;
    }
    return '';
  }

  scheduleMeeting(): void {
    if (!this.meeting) {
      let participants: MeetingParticipant[] = [];
      participants.push(
        ...this.guests.map((g) => ({ user: g } as MeetingParticipant)),
        { user: this.user } as MeetingParticipant,
      );
      if (this.companyRep
        && this.companyRep.uid !== this.authUser.uid) {
        participants.push({ user: this.authUser } as MeetingParticipant);
      }
      participants = participants.filter((obj, index, self) => index === self.findIndex((t) => (
        t.user?.uid === obj.user?.uid
      )));
      const meetingRequest: MeetingRequest = MeetingRequest.hydrate(this.$props);
      const meeting: Meeting = {
        uid: 'new-meeting',
        subject: `${this.$t('calendar.tabs.requests.meeting-with')} ${CommunityUser.hydrate(this.user).fullName}`,
        description: this.message,
        creatorUser: this.companyRep
        && this.companyRep.uid !== this.authUser.uid
          ? this.companyRep
          : this.authUser,
        isCancelled: false,
        participants,
        startTime: this.startTime,
        endTime: this.endTime,
        startTimestamp: this.startTimestamp,
        endTimestamp: this.endTimestamp,
        timeZoneName: this.timezoneName,
      };
      const event = AgendaStoreHelper.convertMeetingToEvent(meeting, this.timezoneName);
      event.meetingRequest = meetingRequest;
      this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
      setTimeout(() => {
        this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(
          DateTimeHelper.utcToZonedTimeDate(
            (this.startTimestamp as number) * 1000,
            this.selectedTzName,
          ),
        ));
        this.setViewMode(ViewMode.CREATE);
        this.setEventEdited(event as Partial<Event>);
      }, 50);
    }
  }

  editMeeting(): void {
    this.$eventsBus.emit('ontoolbox', { view: ToolbarMenuActions.TOOLBAR_AGENDA });
    setTimeout(() => {
      this.setViewMode(ViewMode.EDIT);
      this.setDayBeingViewed(AgendaStoreHelper.formatDictionaryKey(
        DateTimeHelper.utcToZonedTimeDate(
          (this.meeting.startTimestamp as number) * 1000,
          this.selectedTzName,
        ),
      ));
      this.setEventEdited(AgendaStoreHelper.convertMeetingToEvent(this.meeting, this.selectedTzName) as Partial<Event>);
    }, 50);
  }
}
