import Vue from 'vue';
import VueRouter, { Location, Route, RouteConfig } from 'vue-router';
import qs from 'qs';
import BasePage from '@/views/BasePage.vue';
import UiPage from '@/models/graphql/UiPage';
import SignInStep from '@/utils/enums/SignInStep';
import store from '@/store';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/no-community-selected',
    name: 'no-community',
    component: () => import('@/views/error/NoCommunityFound.vue'),
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    beforeEnter: (to, from, next) => {
      const {
        name,
        ...args
      } = to.query;
      if (!name) {
        next({ path: '/' });
      }
      const route: Location = {
        name: name as string,
        replace: true,
      };
      if (args && Object.keys(args).length > 0) {
        if ('query' in args && args.query.length > 0) {
          Object.assign(route, { query: qs.parse(args.query as string) });
          delete args.query;
        }
        route.params = args as Record<string, string>;
      }
      next(route);
    },
  },
  {
    path: '/sales/invoice/:orderId',
    name: 'sales-invoice',
    component: () => import('@/views/sales/Invoice.vue'),
    meta: {
      toolbar: false,
      chatPanel: false,
    },
  },
  {
    path: '/',
    alias: '/home',
    components: {
      default: BasePage,
      mainHeader: () => import('@/views/MainHeader.vue'),
      sideMenu: () => import('@/views/SideMenu.vue'),
      footer: () => import('@/components/FooterComponent.vue'),
    },
    children: [
      {
        path: '/session-redirect/:code',
        name: 'session-redirect',
        meta: {
          toolbar: true,
          chatPanel: true,
        },
        beforeEnter: async (to, from, next) => {
          const sessionUid: string | null = await store.dispatch('SessionStore/getSessionUidByCode', to.params.code);
          if (sessionUid) {
            next({
              name: 'session-detail',
              params: {
                sessionId: sessionUid,
              },
            });
          } else {
            Object.assign(to.meta, { showNotFound: true });
            next();
          }
        },
      },
      {
        path: '/info/:pageType',
        name: 'information',
        props: true,
        component: () => import('@/views/information/InformationPage.vue'),
      },
      {
        path: '/settings',
        name: 'settings',
        redirect: { name: 'settings-account' },
        component: () => import('@/views/settings/MainSettingsPage.vue'),
        children: [
          {
            path: 'account',
            name: 'settings-account',
            component: () => import('@/views/settings/AccountSection.vue'),
            meta: { childTab: true },
          },
          {
            path: 'site-preferences',
            name: 'settings-site-preferences',
            component: () => import('@/views/settings/SitePreferencesSection.vue'),
            meta: { childTab: true },
          },
          {
            path: 'notifications',
            name: 'settings-notifications',
            component: () => import('@/views/settings/NotificationsSection.vue'),
            meta: { childTab: true },
          },
          {
            path: 'security-privacy',
            name: 'settings-security',
            component: () => import('@/views/settings/PrivacySection.vue'),
            meta: { childTab: true },
          },
        ],
      },
    ],
  },
  {
    path: '',
    components: {
      default: BasePage,
      mainHeader: () => import('@/views/MainHeader.vue'),
    },
    children: [
      {
        path: '/email/confirm/:token?',
        name: 'confirm-email',
        props: (route) => ({
          ...route.params,
          standAlone: true,
        }),
        component: () => import('@/views/ConfirmationEmailPage.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/register/:token?',
        name: 'register',
        props: (route) => ({
          ...route.params,
          standAlone: true,
        }),
        component: () => import('@/views/WelcomePage.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/reset-password/:token?',
        name: 'reset-password',
        props: (route) => ({
          ...route.params,
          standAlone: true,
        }),
        component: () => import('@/views/ResetPasswordPage.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/components/auth/LoginPage.vue'),
        props: {
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'reset-password-email',
        component: () => import('@/components/auth/SendToEmailPage.vue'),
        props: {
          pageType: 'reset-password',
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/find-account',
        name: 'find-account-email',
        component: () => import('@/components/auth/SendToEmailPage.vue'),
        props: {
          pageType: 'find-account',
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/find-account/expired',
        name: 'find-account-expired',
        component: () => import('@/views/guest/MessageButtonPage.vue'),
        props: {
          pageType: 'find-account',
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/find-account/email-success',
        name: 'find-account-email-success',
        component: () => import('@/views/guest/MessagePage.vue'),
        props: {
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/find-account/verify',
        name: 'find-account-verify',
        component: () => import('@/views/guest/MessagePage.vue'),
        props: {
          pageType: 'find-account-verify',
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/find-account/complete',
        name: 'find-account-complete',
        component: () => import('@/views/guest/findAccount/FindAccountCompleteForm.vue'),
        props: {
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/create-account',
        name: 'create-account',
        component: () => import('@/components/auth/CreateAccountPage.vue'),
        props: {
          standAlone: true,
        },
        meta: {
          guestOnly: true,
        },
      },
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.ENTER_EMAIL,
          standAlone: true,
        },
        meta: {
          guestOnly: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.SIGN_UP,
          standAlone: true,
        },
        meta: {
          guest: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/no-sign-up',
        name: 'no-sign-up',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.NO_SIGN_UP,
          standAlone: true,
        },
        meta: {
          guestOnly: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.PASSWORD_RESET,
        },
        meta: {
          guestOnly: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/email-sent',
        name: 'email-sent',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.EMAIL_SENT,
          standAlone: true,
        },
        meta: {
          guest: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/enter-information',
        name: 'enter-information',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.ENTER_INFORMATION,
          standAlone: true,
        },
        meta: {
          guest: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/need-activation',
        name: 'need-activation',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.ENTER_INFORMATION,
          standAlone: true,
        },
        meta: {
          guest: false,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/email-disambiguated',
        name: 'email-disambiguated',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.EMAIL_DISAMBIGUATED,
          standAlone: true,
        },
        meta: {
          guest: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/enter-password',
        name: 'enter-password',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.ENTER_PASSWORD,
          standAlone: true,
        },
        meta: {
          guest: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/create-password/:token?',
        name: 'create-password',
        props: (route) => ({
          ...route.params,
          standAlone: true,
          pageType: SignInStep.CREATE_PASSWORD,
        }),
        component: () => import('@/views/account/SignInPage.vue'),
        meta: {
          guestOnly: false,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
      {
        path: '/change-email',
        name: 'change-email',
        component: () => import('@/views/account/SignInPage.vue'),
        props: {
          pageType: SignInStep.CHANGE_EMAIL,
          standAlone: true,
        },
        meta: {
          guest: true,
          header: false,
          footer: false,
          toolbar: false,
          showCta: false,
          showGdpr: false,
          chatPanel: false,
        },
      },
    ],
  },
  {
    path: '*',
    components: {
      default: BasePage,
      mainHeader: () => import('@/views/MainHeader.vue'),
      sideMenu: () => import('@/views/SideMenu.vue'),
      footer: () => import('@/components/FooterComponent.vue'),
    },
    children: [
      {
        path: '',
        name: 'not-found',
        component: () => import('@/views/error/NotFound.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '',
        name: 'forbidden',
        component: () => import('@/views/error/Forbidden.vue'),
        meta: {
          guest: true,
        },
      },
      {
        path: '',
        name: 'error',
        component: () => import('@/views/error/Error.vue'),
        meta: {
          guest: true,
        },
      },
    ],
  },
];

// Recursive method to assign alias to paths from metadata
const localizedRoutes = (paths: RouteConfig[]): RouteConfig[] => paths.map((el) => {
  const meta = el.meta?.translatedAlias;
  const result = el;
  if (meta) {
    return Object.assign(result, { alias: Object.values(meta) });
  }

  if (el.children) {
    return Object.assign(result, { children: localizedRoutes(el.children) });
  }

  return result;
});

export const createRouter = (
  pages: UiPage[],
  logStats: (to: Route, from: Route) => void,
  isUnifyExhibitorPortal = false,
): VueRouter => {
  const homeRoutes = routes.find((r) => r.path === '/') as RouteConfig;
  pages.forEach((p) => {
    if (homeRoutes) {
      if (homeRoutes.children) {
        let path = '';
        if (p.path) {
          path = p.path;
        }
        const foundIndex = homeRoutes.children.findIndex((child) => child.path === path);
        if (foundIndex !== -1) {
          homeRoutes.children.splice(foundIndex, 1);
        }
      }
      const payload = JSON.parse(p.meta || '{}');
      const route: RouteConfig = {
        path: isUnifyExhibitorPortal
        && !p.path?.startsWith(':companyId')
        && !p.path?.includes(':companyId')
          ? `:companyId${p.path}` : p.path || '',
        name: p.code,
        props: {
          page: p,
        },
        alias: payload.alias ? payload.alias : [],
        meta: {
          pageId: p.id,
          // eslint-disable-next-line no-nested-ternary
          entityType: p.entityType?.code
            ? p.entityType.code
            : p.code === 'maps'
              ? 'Map' : null,
          paramName: p.paramName,
          routerKey: p.uid,
          guest: p.guest,
          title: p.name,
          metaTitle: p.metaTitle,
          metaDescription: p.metaDescription,
          childTab: false,
          isParent: p.childPages.length > 0,
          header: p.header,
          footer: p.footer,
          toolbar: p.toolbar,
          showCta: p.showCta,
          showGdpr: p.showGdpr,
          chatPanel: p.chatPanel,
          ...payload.meta,
        },
        beforeEnter: (to, from, next) => {
          const parentRoute = to && to.matched && to.matched.length > 2 ? to.matched[1] : null;
          if (parentRoute && parentRoute.meta && parentRoute.meta.isParent) {
            logStats(parentRoute as unknown as Route, from);
          }
          next();
        },
        component: () => import('@/views/widget/GenericPage.vue'),
      };
      if (p.childPages && p.childPages.length > 0) {
        let defaultTabIndex = p.childPages.findIndex((child) => child.defaultTab === true);
        if (defaultTabIndex < 0) {
          defaultTabIndex = 0;
        }
        route.children = p.childPages.map<RouteConfig>((child, index) => {
          if (index === defaultTabIndex) {
            Object.assign(route, { redirect: { name: child.code } });
          }
          const childPayload = JSON.parse(child.meta || '{}');
          const alias = index === defaultTabIndex
            ? { alias: ['', `${route.path}/`, `${route.path}/${child.path}`] }
            : {};
          return {
            name: child.code,
            path: index === defaultTabIndex ? '' : child.path as string,
            props: {
              page: child,
            },
            ...alias,
            meta: {
              pageId: child.id,
              // eslint-disable-next-line no-nested-ternary
              entityType: p.entityType?.code
                ? p.entityType.code
                : p.code === 'maps'
                  ? 'Map' : null,
              paramName: p.paramName,
              routerKey: p.uid,
              guest: p.guest && child.guest,
              title: child.name,
              metaTitle: child.metaTitle,
              metaDescription: child.metaDescription,
              childTab: true,
              header: p.header,
              footer: p.footer,
              toolbar: p.toolbar,
              showCta: p.showCta,
              showGdpr: p.showGdpr,
              chatPanel: p.chatPanel,
              ...childPayload.meta,
            },
            component: () => import('@/views/widget/GenericPage.vue'),
          };
        })
          .sort((a) => (a.path === '' ? -1 : 1));
      }
      // eslint-disable-next-line no-unused-expressions
      homeRoutes.children?.unshift(route);
    }
  });

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: localizedRoutes(routes),
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query, {
        arrayFormat: 'indices',
        encode: false,
      });
      return result ? (`?${result}`) : '';
    },
    scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (to.meta?.routerKey === from.meta?.routerKey && from.meta?.savedPosition) {
            return resolve(from.meta.savedPosition);
          }
          if (to.meta?.savedPosition) {
            return resolve(to.meta.savedPosition);
          }
          if (to.hash) {
            return resolve({ selector: to.hash });
          }
          if (savedPosition) {
            return resolve(savedPosition);
          }
          return resolve({
            x: 0,
            y: 0,
          });
        }, 500);
      });
    },
  });
};
