









































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import MessageBoxHeader from '@/views/chat/MessageBoxHeader.vue';
import MessageBoxMessages from '@/views/chat/MessageBoxMessages.vue';
import MessageBoxListView from '@/views/chat/MessageBoxListView.vue';
import MessageBoxContent from '@/views/chat/MessageBoxContent.vue';
import MessageGroup from '@/models/graphql/MessageGroup';
import MessageBoxActions from '@/utils/enums/chat/MessageBoxActions';
import MessageBoxNewConversation from '@/views/chat/MessageBoxNewConversation.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import { ChatErrorType } from '@/utils/types/chat/ChatError';
import ChatErrorList from '@/utils/types/chat/ChatErrorList';
import CommunityUser from '@/models/graphql/CommunityUser';
import IllustrationComponent from '@/components/IllustrationComponent.vue';
import IllustrationType from '@/utils/enums/IllustrationType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { BasicTypes } from '@/utils/types/BasicTypes';

@Component({
  methods: { useTestDataAttribute },
  components: {
    FontAwesomeComponent,
    ButtonComponent,
    IllustrationComponent,
    ButtonIconComponent,
    MessageBoxNewConversation,
    MessageBoxContent,
    MessageBoxListView,
    MessageBoxMessages,
    MessageBoxHeader,
  },
  name: 'ToolboxMessageContainer',
})
export default class ToolboxMessageContainer extends BreakpointWrapper {
  @Prop({
    required: false,
    default: 'ChatStore',
  })
  private readonly storeName!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly overrideBasicFilter!: object;

  @Prop({
    required: false,
    default: false,
  })
  private readonly disableGroupCreation!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hideCloseButton!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private forceConversationListTitle!: string;

  private renderMessages = 0;

  private renderHeader = +new Date();

  private ChatErrorType = ChatErrorType;

  private IllustrationType = IllustrationType;

  private get status(): MessageBoxActions {
    return this.state<MessageBoxActions>('status');
  }

  private get chatErrors(): ChatErrorList {
    return this.state<ChatErrorList>('chatErrors');
  }

  private get selectedUsersForNewGroup(): CommunityUser[] {
    return this.state<CommunityUser[]>('selectedUsersForNewGroup');
  }

  private get tempTargetId(): string | null {
    return this.state<string | null>('tempTargetId');
  }

  private get tempTargetType(): string | null {
    return this.state<string | null>('tempTargetType');
  }

  private get tempTargetData(): object | null {
    return this.state<object | null>('tempTargetData');
  }

  private get isClosed(): boolean {
    return this.getter<boolean>('isClosed');
  }

  private get selectedGroup(): MessageGroup | null {
    return this.getter<MessageGroup | null>('selectedGroup');
  }

  private get showMessagesBox(): boolean {
    return MessageBoxActions.MESSAGES === this.status;
  }

  private commit(name: string, payload?: BasicTypes): void {
    this.$store.commit(`ChatDispatcherStore/${this.storeName}/${name}`, payload);
  }

  private getter<T>(name: string): T {
    return this.$store.getters[`ChatDispatcherStore/${this.storeName}/${name}`];
  }

  private state<T>(name: string): T {
    return this.$store.state.ChatDispatcherStore[this.storeName][name];
  }

  private changeStatus(status: MessageBoxActions): void {
    return this.commit('changeStatus', status);
  }

  private setSelectedGroup(group: string | null): void {
    return this.commit('setSelectedGroup', group);
  }

  private toggleChat(close?: boolean): void {
    return this.commit('toggleChat', close);
  }

  @Watch('status')
  @Watch('selectedGroup')
  private reRenderHeader(): void {
    this.renderHeader += 1;
  }

  private openMessages(group: MessageGroup | null): void {
    if (this.selectedGroup?.uid !== group?.uid) {
      this.setSelectedGroup(group ? group?.uid : null);
      this.changeStatus(MessageBoxActions.MESSAGES);
      this.renderMessages += 1;
    }
  }
}
