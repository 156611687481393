











import Vue from 'vue';
import Component from 'vue-class-component';
import DailyView from '@/components/toolbox/agenda/DailyView.vue';
import { namespace, State } from 'vuex-class';
import Event from '@/utils/types/Event';
import ViewMode from '@/utils/enums/agenda/ViewMode';

const agendaStore = namespace('AgendaStore');

@Component({
  components: {
    DailyView,
  },
})
export default class CalendarView extends Vue {
  @agendaStore.Getter
  private fetchViewMode!: string;

  @agendaStore.Getter
  private fetchDayBeingViewed!: string;

  @agendaStore.Getter
  private fetchNewAgendaData!: { [x: string]: Array<Event> };

  @agendaStore.Getter
  private fetchEventEdit!: Event;

  @State
  private dateLocale!: Locale;

  private ViewMode = ViewMode;
}
